type States = 'hidden' | 'visible' | 'closed'

export function useSegmentedPopup() {
  const { activeSegmentId } = useSegment()
  const storyblokStore = useStoryblokStore()
  const segmentedPopups = computed(() => storyblokStore.state.segmentedPopups)
  const segmentedPopup = computed(() => {
    return segmentedPopups.value?.find(popup => popup.segmentId === activeSegmentId.value)
  })

  const segmentedPopupState = ref<States>('hidden')

  if (segmentedPopup.value) {
    const hasLeftThePage = usePageLeave()
    const { idle } = useIdle(15000)
    const { scrolledToBottom, y } = scrollToBottom()

    watch(
      () => [hasLeftThePage.value, segmentedPopupState.value, idle.value, scrolledToBottom.value, y.value],
      () => {
        if ((hasLeftThePage.value && segmentedPopupState.value !== 'closed') || (idle.value && segmentedPopupState.value !== 'closed' || scrolledToBottom.value <= y.value && segmentedPopupState.value !== 'closed')) {
          segmentedPopupState.value = 'visible'
        }
      },
    )
  }

  function closeSegmentedPopup() {
    segmentedPopupState.value = 'closed'
  }

  function scrollToBottom() {
    const body = document?.querySelector('body')
    const bodyHeight = body?.offsetHeight ?? 0
    const windowHeight = window?.innerHeight ?? 0
    const { y } = useWindowScroll()
    const scrolledToBottom = computed(() => {
      return ((bodyHeight - windowHeight) * 75) / 100
    })
    return {
      scrolledToBottom,
      y,

    }
  }

  return {
    segmentedPopupState,
    closeSegmentedPopup,
    scrollToBottom,
    segmentedPopup,
  }
}
